
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}
a {
  text-decoration: none;
}
code {
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: turquoise;
  transform-origin: 0%;
  z-index: 2222;
}
::selection {

  background: #ffe984;
}
